import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import TgIndex from '../views/TgIndex.vue'
import { ElMessage } from 'element-plus'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/TgLogin', // 登录
        name: 'TgLogin',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '@/views/TgLogin/TgLogin.vue'
            ),
    },
    {
        path: '/',
        name: 'TgIndex',
        component: TgIndex,
        children: [
            // {
            // 	path: '/',// 首页
            // 	name: 'ShadowIndex',
            // 	redirect: '/ShadowIndex',
            // },

            {
                path: '/', // 首页
                name: 'ShadowIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '@/views/ShadowIndex/ShadowIndex.vue'
                    ),
            },
            {
                path: '/agreement', // 协议
                name: 'agreement',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '@/views/agreement.vue'
                    ),
            },
            {
                path: '/aboutnr', // 关于我们
                name: 'aboutnr',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '@/views/about/about.vue'
                    ),
            },
            {
                path: '/materialManagement', // 器材管理
                name: 'materialManagement',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '@/views/materialManagement/materialManagement.vue'
                    ),
            },
            {
                path: '/ShadowComposer', //影像创作人
                name: 'ShadowComposer',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowComposer/ShadowComposer.vue'
                    ),
            },
            {
                path: '/ShadowComposerHomepage', //影像创作人主页
                name: 'ShadowComposerHomepage',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowComposer/ShadowComposerHomepage.vue'
                    ),
            },
            {
                path: '/ShadowActivity',
                name: 'ShadowActivity',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowActivity/ShadowActivity.vue'
                    ),
            },
            {
                path: '/ShowActivity',
                name: 'ShowActivity',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowActivity/ShowActivity.vue'
                    ),
            }, {
                path: '/allProduction',
                name: 'allProduction',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowActivity/allProduction.vue'
                    ),
            }, {
                path: '/drawList',
                name: 'drawList',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowActivity/drawList.vue'
                    ),
            }, {
                path: '/monthly',
                name: 'monthly',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/monthly/index.vue'
                    ),
            }, {
                path: '/companyMonthly',
                name: 'companyMonthly',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/companyMonthly/index.vue'
                    ),
            },
            {
                path: '/AddMaterial',
                name: 'AddMaterial',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowActivity/AddMaterial.vue'
                    ),
            },
            {
                path: '/JoinActivity',
                name: 'JoinActivity',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowActivity/JoinActivity.vue'
                    ),
            },
            {
                path: '/ShadowBusiness',
                name: 'ShadowBusiness',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowBusiness/ShadowBusiness.vue'
                    ),
            },
            {
                path: '/ShadowBusinessList',
                name: 'ShadowBusinessList',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowBusiness/ShadowBusinessList.vue'
                    ),
            },
            {
                path: '/ShadowBusinessParticulars',
                name: 'ShadowBusinessParticulars',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowBusiness/ShadowBusinessParticulars.vue'
                    ),
            },
            {
                // 	影像器材
                path: '/ShadowEquipment',
                name: 'ShadowEquipment',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowEquipment/ShadowEquipment.vue'
                    ),
            },
            {
                path: '/moduleImageInformation',
                name: 'moduleImageInformation',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowEquipment/moduleImageInformation.vue'
                    ),
            },
            {
                // 影像素材
                path: '/ShadowMaterial',
                name: 'ShadowMaterial',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowMaterial/ShadowMaterial.vue'
                    ),
            },
            {
                // 影像素材
                path: '/ShadowMaterialVideoList',
                name: 'ShadowMaterialVideoList',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowMaterial/ShadowMaterialVideoList.vue'
                    ),
            },
            {
                // 视频详情页
                path: '/ShadowMaterialVideoParticulars',
                name: 'ShadowMaterialVideoParticulars',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowMaterial/ShadowMaterialVideoParticulars.vue'
                    ),
            },

            {
                // 视频详情页
                path: '/ShadowMaterialVideoMusic',
                name: 'ShadowMaterialVideoMusic',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowMaterial/ShadowMaterialVideoMusic.vue'
                    ),
            },
            {
                // 图片详情页
                path: '/ShadowMaterialVideoImage',
                name: 'ShadowMaterialVideoImage',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowMaterial/ShadowMaterialVideoImage.vue'
                    ),
            },
            {
                path: '/ShadowTrain',
                name: 'ShadowTrain',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowTrain/ShadowTrain.vue'
                    ),
            },
            {
                // 培训列表页
                path: '/ShadowTrainList',
                name: 'ShadowTrainList',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowTrain/ShadowTrainList.vue'
                    ),
            },
            {
                // 培训详情页
                path: '/ShadowTrainDetailPage',
                name: 'ShadowTrainDetailPage',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowTrain/ShadowTrainDetailPage.vue'
                    ),
            },

            {
                path: '/MessageBarList',
                name: 'MessageBarList',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/MessageBarList/MessageBarList.vue'
                    ),
            },
            {
                path: '/ShadowIndexAllWorks',
                name: 'ShadowIndexAllWorks',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowIndexAllWorks/ShadowIndexAllWorks.vue'
                    ),
            },
            /* 个人主页 */
            {
                path: '/ShadowUser',
                name: 'ShadowUser',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/ShadowUser/ShadowUser.vue'
                    ),
            },

            // =====================================================

            // =====================================================
            {
                path: '/about',
                name: 'about',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/AboutView.vue'
                    ),
            },
            //上传
            {
                path: '/uploadData',
                name: 'uploadData',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/uploadFiles/upload_data.vue'
                    ),
            },
            {
                path: '/uploadWorks',
                name: 'uploadWorks',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/uploadFiles/upload_works.vue'
                    ),
            },
            /* 新版本的 */
            {
                path: '/materialUpload',
                name: 'materialUpload',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/material/materialUploadNew/material_upload.vue'
                    ),
            },
            {
                path: '/materiaPerfect',
                name: 'materiaPerfect',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/material/materialUploadNew/materia_perfect.vue'
                    ),
            },
            {
                path: '/musicPerfect',
                name: 'musicPerfect',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/material/materialUploadNew/music_perfect.vue'
                    ),
            },
            {
                path: '/picturePerfect',
                name: 'picturePerfect',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/material/materialUploadNew/picture_perfect.vue'
                    ),
            },
            {
                path: '/ceshiIndex',
                name: 'ceshiIndex',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/material/materialUploadNew/ceshi_index.vue'
                    ),
            },
            {
                //在审素材
                path: '/newTrialMaterial',
                name: 'newTrialMaterial',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/material/newTrialMaterial.vue'
                    ),
            },
            {
                //在售素材
                path: '/onSaleMaterial',
                name: 'onSaleMaterial',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/material/onSaleMaterial.vue'
                    ),
            },
            {
                // 以购素材
                path: '/purchasedMaterial',
                name: 'purchasedMaterial',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/material/purchasedMaterial.vue'
                    ),
            },
            //需求
            {
                // 需求管理
                path: '/requirementManagement',
                name: 'requirementManagement',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/requirement/requirement_management.vue'
                    ),
            },
            {
                //发布需求
                path: '/requirementPublish',
                name: 'requirementPublish',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/requirement/requirement_publish.vue'
                    ),
            },

            {
                //发票
                path: '/myDebit',
                name: 'myDebit',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/my_debit.vue'
                    ),
            },
            {
                // 发票详情
                path: '/invoiceDebit',
                name: 'invoiceDebit',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/invoice_debit.vue'
                    ),
            },
            {
                // 交易记录
                path: '/tradingRecord',
                name: 'tradingRecord',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/trading_record.vue'
                    ),
            },
            {
                // 交易需求
                path: '/tradingRequirement',
                name: 'tradingRequirement',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/trading_requirement.vue'
                    ),
            },
            {
                // 我的银行卡
                path: '/bankCard',
                name: 'bankCard',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/bank_card.vue'
                    ),
            },

            {
                //提现
                path: '/withdraw',
                name: 'withdraw',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/withdraw.vue'
                    ),
            },
            {
                //我的订单
                path: '/myOrder',
                name: 'myOrder',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/my_order.vue'
                    ),
            },
            {
                //收银台
                path: '/myOrderCheckstand',
                name: 'myOrderCheckstand',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/my_order_checkstand.vue'
                    ),
            },
            {
                //支付详情

                path: '/myOrderDetial',
                name: 'myOrderDetial',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/debit/my_order_detial.vue'
                    ),
            },
            //版权
            {
                path: '/CopyrightPage',
                name: 'copyright',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/copyright/copyrightPage.vue'
                    ),
            },
            {
                path: '/AuthenTicationHome',
                name: 'AuthenTicationHome',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/authentication/authenTicationHome.vue'
                    ),
            },
            //认证
            {
                path: '/AuthenTication',
                name: 'AuthenTication',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/authentication/authenTication.vue'
                    ),
            },

            //购物车
            {
                path: '/purchasedCar',
                name: 'purchasedCar',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/purchasedCar/purchasedCar.vue'
                    ),
            },
            //意见反馈
            {
                path: '/feedback',
                name: 'feedback',
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/feedback/feedback.vue'
                    ),
            },
        ],
    },
]

// router.afterEach((to, from, next) => {
// 	window.scrollTo(0, 0)
// })

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 滚动到顶部
        return { top: 0 }
    },
})
// const router = new Router({

// });
let isToken = localStorage.getItem('token')
let isChecked = false
let list = [
    'ShadowIndex',
    'ShadowIndexAllWorks',
    'ShadowComposer',
    'ShadowComposerHomepage',
    'ShadowMaterial',
    'ShadowMaterialVideoParticulars',
    'ShadowMaterialVideoMusic',
    'ShadowMaterialVideoImage',
    'ShadowMaterialVideoList',
    'ShadowBusiness',
    'ShadowBusinessParticulars',
    'ShadowEquipment',
    'moduleImageInformation',
    'ShadowTrain',
    'ShadowTrainDetailPage',
    'ShadowActivity',
    'ShowActivity',
    'AddMaterial',
    'drawList',
    "aboutnr",
    'TgLogin',
    "allProduction",
    "monthly",
    "companyMonthly"
]
router.beforeEach((to, from, next) => {
    // console.log(to)
    isChecked = false
    for (let i = 0; i < list.length; i++) {
        if (to.name == list[i]) {
            isChecked = true
            document
                .getElementsByName('viewport')[0]
                .setAttribute(
                    'content',
                    'width=device-width, initial-scale=0.25, minimum-scale=0.24, maximum-scale=2.01, user-scalable=yes'
                )
            document
                .getElementsByName('viewport')[0]
                .setAttribute(
                    'content',
                    'width=device-width, initial-scale=0.24, minimum-scale=0.24, maximum-scale=2.01, user-scalable=yes'
                )
            next()
            return
        }
    }
    // console.log(isToken)
    // console.log(isChecked)
    if (!isChecked && !localStorage.getItem('token')) {
        console.log('to.name', to.name)
        if (to.name != 'agreement') {
            ElMessage({
                showClose: true,
                message: '登录过期,请重新登录',
                type: 'warning',
            })
            document
                .getElementsByName('viewport')[0]
                .setAttribute(
                    'content',
                    'width=device-width, initial-scale=0.25, minimum-scale=0.24, maximum-scale=2.01, user-scalable=yes'
                )
            document
                .getElementsByName('viewport')[0]
                .setAttribute(
                    'content',
                    'width=device-width, initial-scale=0.24, minimum-scale=0.24, maximum-scale=2.01, user-scalable=yes'
                )
            next({ name: 'ShadowIndex' })
        }
    }
    document
        .getElementsByName('viewport')[0]
        .setAttribute(
            'content',
            'width=device-width, initial-scale=0.25, minimum-scale=0.24, maximum-scale=2.01, user-scalable=yes'
        )
    document
        .getElementsByName('viewport')[0]
        .setAttribute(
            'content',
            'width=device-width, initial-scale=0.24, minimum-scale=0.24, maximum-scale=2.01, user-scalable=yes'
        )
    next()
})

export default router
